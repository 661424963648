/* my stuff */

@media (min-width: 991px) {
  .col-lg-mod-1 {
    flex: 0 0 2%;
    max-width: 2%;
  }

  .col-lg-mod-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-mod-9 {
    flex: 0 0 69%;
    max-width: 69%;
  }
}

.container {
  position: relative;
  width: 100%;
  padding: 0;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
  background-color: rgba(0,0,0,0);
}

.nav-item {
  cursor: pointer;
}

.nav-link {
  background: -webkit-linear-gradient(330deg, #ef5350, #e53935);
  background: -o-linear-gradient(330deg, #ef5350, #e53935);
  background: linear-gradient(60deg, #ef5350, #e53935);
}

#attendee_list {
  padding-top: 10px;
  padding-bottom: 10px;
}

.card_title_text {
  margin-bottom: 0;
}
